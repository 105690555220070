import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { statusIncidencia } from '../../../actions/colaboradores';

const StatusIncidencia = ({ incidencia, reestablecerIncidencia }) => {

    const dispatch =  useDispatch();

    const [incidenciaData, setIncidenciaData] = useState({
        id: null, 
        id_colaborador: null, 
        status: null, 
        formato: 0
    });

    const [statusModal, setStatusModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Status incidencia */
    const handleProcesar = async (status) => {
        await setStatusProcesar(true);
        await dispatch(statusIncidencia({
            ...incidenciaData, 
            status
        }));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerIncidencia(null);
            setStatusModal(false);
        }
    }

    /* Funciones generales */
    const convertPeriodosDias = (periodos) => {
        let dias = 0;
        let cantidad_dia = 1000 * 60 * 60 * 24;

        if(periodos?.length > 0){
            for (var x = 0; x < periodos.length; x++) {
                let diferencia = Math.floor(new Date(periodos[x].fin).getTime() - new Date(periodos[x].inicio).getTime());
                dias += Math.floor(diferencia / cantidad_dia) + 1;
            }
        }

        return dias;
    }

    useEffect(() => {
        if(incidencia){
            setStatusModal(true);

            setIncidenciaData({
                ...incidenciaData, 
                id: incidencia.id,
                id_colaborador: incidencia.id_colaborador,
                status: incidencia.status
            });
        }
    }, [incidencia]);

    return (
        <Modal show={statusModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Status de incidencia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿A que status deseas actualizar la incidencia de {convertPeriodosDias(incidencia?.periodos)} {convertPeriodosDias(incidencia?.periodos) != 1 ? 'dias' : 'dia'}?</p>
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar && incidenciaData.status !== 3 ?
                        <button type="button" className="btn btn-danger mr-2" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Rechazada</button>
                    :
                        incidenciaData.status !== 3 ?
                            <button type="button" className="btn btn-danger mr-2" onClick={() => handleProcesar(3)}><i className="fas fa-times fa-sm"></i> Rechazada</button>
                        :
                            null
                }

                {
                    statusProcesar && incidenciaData.status !== 1 ?
                        <button type="button" className="btn btn-warning mr-2" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Pendiente</button>
                    :
                        incidenciaData.status !== 1 ?
                            <button type="button" className="btn btn-warning mr-2" onClick={() => handleProcesar(1)}><i className="fas fa-pause fa-sm"></i> Pendiente</button>
                        :
                            null
                }

                {
                    statusProcesar && incidenciaData.status !== 2 ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Autorizada</button>
                    :
                        incidenciaData.status !== 2 ?
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(2)}><i className="fas fa-check fa-sm"></i> Autorizada</button>
                        :
                            null
                }
            </Modal.Footer>
        </Modal>
    )
}

export default StatusIncidencia;
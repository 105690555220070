import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../../actions/alert';
import { statusColaborador } from '../../../actions/colaboradores';

const StatusColaborador = ({ colaborador, reestablecerColaborador }) => {

    const dispatch =  useDispatch();

    const [statusData, setStatusData] = useState({
        id: null, 
        inicio: new Date(), 
        fin: new Date(), 
        observaciones: '', 
        status: 0
    });

    const [statusModal, setStatusModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Status contratacion */
    const handleInputChange = ({ target }) => {
        setStatusData({
            ...statusData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setStatusData({
            ...statusData,
            [name]: value
        });
    }

    const handleProcesar = async () => {
        if(statusData.inicio || statusData.fin){
            await setStatusProcesar(true);
            await dispatch(statusColaborador(statusData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('La fecha es requerida', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setStatusData({
                id: null, 
                inicio: new Date(), 
                fin: new Date(), 
                observaciones: '', 
                status: 0
            });
            reestablecerColaborador(null, 0);
            setStatusModal(false);
        }
    }

    useEffect(() => {
        if(colaborador.colaborador){
            setStatusModal(true);

            setStatusData({
                ...statusData, 
                id: colaborador.colaborador, 
                status: colaborador.status
            });
        }
    }, [colaborador]);

    return (
        <Modal show={statusModal} size="md" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>{colaborador.status == 10 ? (colaborador.contrataciones == 0 ? 'Contratación' : 'Despido / Renuncia') : (colaborador.contrataciones == 0 ? 'Contratación' : 'Recontratación')} de colaborador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {
                        colaborador.status == 10 ?
                            (
                                colaborador.contrataciones == 0 ? 
                                    <div className="col-md-12 form-group mb-3">
                                        <div className="input-group">
                                            <div className="form-floating">
                                                <DatePicker
                                                    id="inicio"
                                                    name="inicio"
                                                    className="form-control text-center input-datepícker"
                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete="off"
                                                    selected={ statusData.inicio != null ? new Date(statusData.inicio) : '' }
                                                    onChange={ date => handleDatePicker('inicio', date) }
                                                />
                                                <label className="label-datepicker" htmlFor="inicio">Inicio de contrato</label>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className="col-md-12 form-group mb-3">
                                        <div className="input-group">
                                            <div className="form-floating">
                                                <DatePicker
                                                    id="fin"
                                                    name="fin"
                                                    className="form-control text-center input-datepícker"
                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    autoComplete="off"
                                                    selected={ statusData.fin != null ? new Date(statusData.fin) : '' }
                                                    onChange={ date => handleDatePicker('fin', date) }
                                                />
                                                <label className="label-datepicker" htmlFor="fin">Finalización de contrato</label>
                                            </div>
                                        </div>
                                    </div>
                            )
                        :   
                            <div className="col-md-12 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <DatePicker
                                            id="inicio"
                                            name="inicio"
                                            className="form-control text-center input-datepícker"
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            autoComplete="off"
                                            selected={ statusData.inicio != null ? new Date(statusData.inicio) : '' }
                                            onChange={ date => handleDatePicker('inicio', date) }
                                        />
                                        <label className="label-datepicker" htmlFor="inicio">Inicio de contrato</label>
                                    </div>
                                </div>
                            </div>
                    }

                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <textarea className="form-control textareCampo" placeholder="Observaciones" id="observaciones" name="observaciones" value={statusData.observaciones} onChange={e => handleInputChange(e)}></textarea>
                                <label htmlFor="observaciones">Observaciones</label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default StatusColaborador;
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { eliminarTipoColaborador } from '../../../actions/tipos_colaboradores';

const EliminarTipo = ({ tipo, reestablecerTipo }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar tipo */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(eliminarTipoColaborador(tipo?.id));
        await handleCerrar(null);
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerTipo(null);
            setEliminarModal(false);
        }
    }

    useEffect(() => {
        if(tipo != null){
            setEliminarModal(true);
        }
    }, [tipo]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar tipo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    tipo?.colaboradores > 0 ?
                        <p>Si deseas eliminar el tipo de colaborador "{tipo?.nombre}" primero debes de reasignar {tipo?.colaboradores > 1 ? 'los colaboradores' : 'el colaborador'} que tienen este tipo asingado a otro.</p>
                    :
                        <p>¿Deseas eliminar el tipo "{tipo?.nombre}"?</p>

                }
            </Modal.Body>
            <Modal.Footer>
            {
                tipo?.colaboradores == 0 ? 
                    (
                        <>
                            <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={statusProcesar}><i className="fas fa-times fa-sm"></i> No</button>
                            {
                                statusProcesar ? (
                                    <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                                ) : (
                                    <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                                )
                            }
                        </>
                    ) 
                :
                    <Link to={`/colaboradores/colaboradores`}><button className="btn btn-primary">Ir a colaboradores</button></Link>
            }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarTipo;
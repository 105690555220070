import React from 'react';

export const CalendarEvent = ({event}) => {

    const {title} = event;

    return (
        <div>
            <div><strong className="texto-completo">{title}</strong></div>
        </div>
    )
}
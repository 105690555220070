import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { setAlert } from '../../../actions/alert';
import { crearProveedor } from '../../../actions/proveedores';

const NuevoProveedor = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Proveedores',
            path: '/egresos/proveedores'
        },
        {
            activo: true,
            nombre: 'Nuevo proveedor',
            path: '/egresos/proveedores/nuevo'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [proveedorData, setProveedorData] = useState({
        razon_social: '', 
        alias: '', 
        rfc: '', 
        domicilio: '', 
        banco: '', 
        clabe: '', 
        nombre_contacto: '', 
        telefono: '', 
        correo: '', 
        descripcion: ''
    });

    /* Nuevo proveedor */
    const handleInputChange = ({ target }) => {
        setProveedorData({
            ...proveedorData,
            [target.name]: target.value
        });
    }

    const handleGuardarProveedor = async () => {
        if(proveedorData.alias){
            await dispatch(crearProveedor(proveedorData));

            await navigate("/egresos/proveedores");
        }else{
            await dispatch(setAlert('Al menos el alias es requerido', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-5 mt-5">Nuevo proveedor</h3>

                    <div className="row mb-3">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Alias" id="alias" name="alias" value={proveedorData.alias} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="alias">Alias</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Razón social" id="razon_social" name="razon_social" value={proveedorData.razon_social} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="razon_social">Razón social</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="RFC" id="rfc" name="rfc" value={proveedorData.rfc} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="rfc">RFC</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Domicilio fiscal" id="domicilio" name="domicilio" value={proveedorData.domicilio} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="domicilio">Domicilio fiscal</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Banco" id="banco" name="banco" value={proveedorData.banco} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="banco">Banco</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Clabe interbancaria" id="clabe" name="clabe" value={proveedorData.clabe} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="clabe">Clabe interbancaria</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Persona de contacto" id="nombre_contacto" name="nombre_contacto" value={proveedorData.nombre_contacto} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre_contacto">Persona de contacto</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Teléfono" id="telefono" name="telefono" value={proveedorData.telefono} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="telefono">Teléfono</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Correo" id="correo" name="correo" value={proveedorData.correo} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="correo">Correo</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Descripción" id="descripcion" name="descripcion" value={proveedorData.descripcion} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="descripcion">Descripción</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarProveedor}>Guardar</button>
                            <Link to='/egresos/proveedores'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevoProveedor;
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarUnidadesConfiguracion } from '../../../actions/configuracion';

import NuevaUnidad from './NuevaUnidad';
import EditarUnidad from './EditarUnidad';
import EliminarUnidad from './EliminarUnidad';

const Unidades = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Unidades',
            path: '/configuracion/unidades'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.unidades);

    const [unidades, setUnidades] = useState([]);

    const [unidadAgregar, setUnidadAgregar] = useState(null);
    const [unidadEditar, setUnidadEditar] = useState(null);
    const [unidadEliminar, setUnidadEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Descripción',
            selector: row => row.descripcion,
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarUnidad(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarUnidad(row)}><i className="fas fa-edit text-warning"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Crear unidad */
    const handleAgregarUnidad = (unidad) => {
        setUnidadEditar(null);
        setUnidadEliminar(null);

        setUnidadAgregar(unidad);        
    }

    /* Editar unidad */
    const handleEditarUnidad = (unidad) => {
        setUnidadAgregar(null);
        setUnidadEliminar(null);

        setUnidadEditar(unidad);
    }

    /* Eliminar unidad */
    const handleEliminarUnidad = (unidad) => {
        setUnidadAgregar(null);
        setUnidadEditar(null);

        setUnidadEliminar(unidad);
    }

    useEffect(() => {
        dispatch(buscarUnidadesConfiguracion());
    }, []);

    useEffect(() => {
        setUnidades(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevaUnidad unidad={unidadAgregar} reestablecerUnidad={handleAgregarUnidad} />
            <EditarUnidad unidad={unidadEditar} reestablecerUnidad={handleEditarUnidad} />
            <EliminarUnidad unidad={unidadEliminar} reestablecerUnidad={handleEliminarUnidad} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Unidades</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarUnidad({ })}>Agregar unidad</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={unidades}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Unidades;
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { eliminarSubcategoriaConfiguracion } from '../../../../actions/configuracion';

const EliminarSubcategoria = ({ subcategoria, reestablecerSubcategoria }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar subcategoria */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(eliminarSubcategoriaConfiguracion(subcategoria?.id));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerSubcategoria(null);
            setEliminarModal(false);
        }
    }

    useEffect(() => {
        if(subcategoria){
            setEliminarModal(true);
        }
    }, [subcategoria]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar categoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Deseas eliminar a <b>{subcategoria?.nombre}{subcategoria?.subcategorias > 0 ? ' con ' + subcategoria?.subcategorias + ' asignada' + (subcategoria?.subcategorias == 1 ? '' : 's') : ''}</b>?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarSubcategoria;
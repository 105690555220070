import { ORDENES } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case ORDENES.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case ORDENES.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case ORDENES.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( orden => orden.id !== payload )
            };
        
        default:
            return state;
    }
}
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setAlert } from '../../../actions/alert';
import { limpiarUnidadConfiguracion, obtenerUnidadConfiguracion, editarUnidadConfiguracion } from '../../../actions/configuracion';

const EditarUnidad = ({ unidad, reestablecerUnidad }) => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.configuracion.unidades);

    const [editarModal, setEditarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [unidadData, setUnidadData] = useState({
        id: null,
        nombre: null, 
        descripcion: null
    });

    /* Editar unidad */
    const handleInputChange = ({ target }) => {
        setUnidadData({
            ...unidadData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async () => {
        if(unidadData.nombre){
            await setStatusProcesar(true);
            await dispatch(editarUnidadConfiguracion(unidadData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setUnidadData({
                id: null,
                nombre: null, 
                descripcion: null
            });
            setEditarModal(false);
            reestablecerUnidad(null);
        }
    }

    useEffect(() => {
        dispatch(limpiarUnidadConfiguracion());
    }, []);

    useEffect(() => {
        if(unidad){
            setEditarModal(true);
            
            dispatch(obtenerUnidadConfiguracion(unidad?.id));

            setUnidadData({
                ...unidadData,
                id: unidad?.id
            });
        }
    }, [unidad]);

    useEffect(() => {
        if(detalles){
            setUnidadData({
                ...unidadData,
                id: detalles?.id, 
                nombre: detalles?.nombre,
                descripcion: detalles?.descripcion
            });
        }
    }, [detalles]);

    return (
        <Modal show={editarModal} backdrop="static" size="md" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Editar unidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={unidadData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Descripción" id="descripcion" name="descripcion" value={unidadData.descripcion} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="descripcion">Descripción</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Guardar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Guardar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditarUnidad;
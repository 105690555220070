import { DASHBOARD } from '../actions/types'

const initialState = {};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case DASHBOARD.OBTENER:
            return payload;

        default:
            return state;
    }
}
import { LOADER } from './types'

export const changeLoader = show => dispatch => {
    if (show) {
        dispatch({
            type: LOADER.MOSTRAR,
            payload: {}
        });
    } else {
        dispatch({
            type: LOADER.OCULTAR,
            payload: {}
        });
    }
}
import axios from 'axios';

import { COLABORADORES, CALENDARIO } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarColaboradores = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.BUSCAR,
            payload: res.data.colaboradores
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const buscarColaboradoresEmpresa = (empresa) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        empresa
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/listar_empresa`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.BUSCAR,
            payload: res.data.colaboradores
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearColaborador = (colaborador) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(colaborador);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/crear`, body, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const informacionColaborador = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.DETALLES,
            payload: res.data.colaborador
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerColaborador = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/perfil`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.DETALLES,
            payload: res.data.colaborador
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarColaborador = () => dispatch => {
    dispatch({
        type: COLABORADORES.DETALLES,
        payload: null
    });
}

export const editarColaborador = (colaborador) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(colaborador);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/editar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.DETALLES,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const statusColaborador = (colaborador) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(colaborador);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/status`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.CONTRATAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarColaborador = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/eliminar`, body, config);

        await dispatch({
            type: COLABORADORES.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Contrataciones */

export const editarContratacion = (contratacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(contratacion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/editar_contratacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.CONTRATACIONES.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarContratacion = (contratacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(contratacion);

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/eliminar_contratacion`, body, config);

        await dispatch({
            type: COLABORADORES.CONTRATACIONES.LISTAR,
            payload: res.data.colaborador
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Vacaciones */

export const crearVacacion = (vacacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(vacacion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/crear_vacacion`, body, config);
        await dispatch(changeLoader(false));
        
        if(vacacion.formato == 0){
            await dispatch({
                type: COLABORADORES.VACACIONES.LISTAR,
                payload: res.data.colaborador
            });
        }

        if(vacacion.formato == 1){
            await dispatch({
                type: CALENDARIO.AGREGAR,
                payload: res.data.eventos
            });
        }

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarVacacion = (vacacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(vacacion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/editar_vacacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.VACACIONES.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const statusVacacion = (vacacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(vacacion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/status_vacacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.VACACIONES.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarVacacion = (vacacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(vacacion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/eliminar_vacacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.VACACIONES.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Incidencias */

export const crearIncidencia = (incidencia) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(incidencia);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/crear_incidencia`, body, config);
        await dispatch(changeLoader(false));

        if(incidencia.formato == 0){
            await dispatch({
                type: COLABORADORES.INCIDENCIAS.LISTAR,
                payload: res.data.colaborador
            });
        }

        if(incidencia.formato == 1){
            await dispatch({
                type: CALENDARIO.AGREGAR,
                payload: res.data.eventos
            });
        }

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarIncidencia = (incidencia) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(incidencia);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/editar_incidencia`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.INCIDENCIAS.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const statusIncidencia = (incidencia) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(incidencia);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/status_incidencia`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.INCIDENCIAS.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarIncidencia = (incidencia) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(incidencia);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/eliminar_incidencia`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.INCIDENCIAS.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Remotos */

export const crearRemoto = (remoto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(remoto);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/crear_remoto`, body, config);
        await dispatch(changeLoader(false));

        if(remoto.formato == 0){
            await dispatch({
                type: COLABORADORES.REMOTOS.LISTAR,
                payload: res.data.colaborador
            });
        }

        if(remoto.formato == 1){
            await dispatch({
                type: CALENDARIO.AGREGAR,
                payload: res.data.eventos
            });
        }

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarRemoto = (remoto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(remoto);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/editar_remoto`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.REMOTOS.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const statusRemoto = (remoto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(remoto);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/status_remoto`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.REMOTOS.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarRemoto = (remoto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(remoto);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/eliminar_remoto`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.REMOTOS.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Reportes */

export const crearReporte = (reporte) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(reporte);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/crear_reporte`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.REPORTES.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarReporte = (reporte) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(reporte);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/editar_reporte`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.REPORTES.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const statusReporte = (reporte) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(reporte);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/status_reporte`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.REPORTES.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarReporte = (reporte) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(reporte);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/eliminar_reporte`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.REPORTES.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Archivos */

export const crearArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/crear_archivo`, archivo, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.ARCHIVOS.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/editar_archivo`, archivo, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.ARCHIVOS.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const detallesArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(archivo);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/detalles_archivo`, body, config);
        await dispatch(changeLoader(false));

        dispatch({
            type: COLABORADORES.ARCHIVOS.DETALLES,
            payload: res.data.archivo
        });
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarArchivo = () => dispatch => {
    dispatch({
        type: COLABORADORES.ARCHIVOS.DETALLES,
        payload: null
    });
}

export const eliminarArchivo = (archivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(archivo);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/eliminar_archivo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: COLABORADORES.ARCHIVOS.LISTAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}
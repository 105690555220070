import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { eliminarAreaConfiguracion } from '../../../actions/configuracion';

const EliminarArea = ({ area, reestablecerArea }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar area */
    const handleProcesar = async (tipo) => {
        await setStatusProcesar(true);
        await dispatch(eliminarAreaConfiguracion({
            id: area?.id, 
            tipo
        }));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerArea(null);
            setEliminarModal(false);
        }
    }

    useEffect(() => {
        if(area){
            setEliminarModal(true);
        }
    }, [area]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar área</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Deseas eliminar a <b>{area?.nombre}{area?.organizadores > 0 ? ' con ' + area?.organizadores + (area?.organizadores == 1 ? ' organizador asignado' : 'organizadores asignados') : ''}</b>?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <>
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si, solo área</button>
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si, área y subniveles</button>
                        </>
                    :
                        <>
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(1)}><i className="fas fa-check fa-sm"></i> Si, solo área</button>
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(2)}><i className="fas fa-check fa-sm"></i> Si, área y subniveles</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarArea;
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { setAlert } from '../../../actions/alert';
import { buscarProveedores } from '../../../actions/proveedores';
import { buscarMetodosConfiguracion } from '../../../actions/configuracion';
import { crearOrden } from '../../../actions/ordenes';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const NuevaOrden = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Ordenes',
            path: '/egresos/ordenes'
        },
        {
            activo: true,
            nombre: 'Nueva orden',
            path: '/egresos/proveedores/nuevo'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { listado: listado_proveedores } = useSelector(state => state.proveedores);
    const { listado: listado_metodos } = useSelector(state => state.configuracion.metodos);

    const [ordenData, setOrdenData] = useState({
        id_proveedor: null,
        id_metodo: null,
        monto: '', 
        productos: []
    });

    const [proveedorData, setProveedorData] = useState(null);
    const [proveedoresFilter, setProveedoresFilter] = useState([]);

    const [metodoData, setMetodoData] = useState(null);
    const [metodosFilter, setMetodosFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Nuevo proveedor */
    const handleSelectProveedor = (option) => {
        setOrdenData({
            ...ordenData,
            id_proveedor: option ? option.value : null
        });

        setProveedorData(option);
    }

    const handleSelectMetodo = (option) => {
        setOrdenData({
            ...ordenData,
            id_metodo: option ? option.value : null
        });

        setMetodoData(option);
    }

    const handleNumberChange = (value) => {
        setOrdenData({
            ...ordenData,
            monto: value || ''
        });
    }

    const handleGuardarOrden = async () => {
        if(ordenData.productos.length > 0){
            await dispatch(crearOrden(ordenData));

            await navigate("/egresos/ordenes");
        }else{
            await dispatch(setAlert('Debe de haber al menos un producto', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(buscarProveedores());
        dispatch(buscarMetodosConfiguracion());
    }, []);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_proveedores.length; i++) {
            options.push({ value: listado_proveedores[i].id, label: listado_proveedores[i].alias ? listado_proveedores[i].alias + ' (' + listado_proveedores[i].razon_social + ')' : listado_proveedores[i].razon_social });
        }

        setProveedoresFilter(options);
    }, [listado_proveedores]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_metodos.length; i++) {
            options.push({ value: listado_metodos[i].id, label: listado_metodos[i].nombre });
        }

        setMetodosFilter(options);
    }, [listado_metodos]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-5 mt-5">Nueva orden</h3>

                    <div className="row mb-3">
                        <div className="col-md-6 form-group mb-2">
                            <div className="input-group h-100">
                                <Select
                                    name="id_proveedor"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Proveedor"
                                    options={proveedoresFilter}
                                    value={proveedorData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectProveedor(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group h-100">
                                <Select
                                    name="id_metodo"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Método de pago"
                                    options={metodosFilter}
                                    value={metodoData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectMetodo(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <CurrencyInput
                                        id="monto"
                                        name="monto"
                                        className="form-control text-center"
                                        value={ordenData.monto || ''}
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value) => handleNumberChange(value)}
                                    />
                                    <label htmlFor="monto">Monto total</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarOrden}>Guardar</button>
                            <Link to='/egresos/ordenes'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevaOrden;
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarAreasConfiguracion } from '../../../actions/configuracion';

import NuevaArea from './NuevaArea';
import EditarArea from './EditarArea';
import EliminarArea from './EliminarArea';

const Areas = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Áreas',
            path: '/configuracion/areas'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.areas);

    const [areas, setAreas] = useState([]);

    const [areaAgregar, setAreaAgregar] = useState(null);
    const [areaEditar, setAreaEditar] = useState(null);
    const [areaEliminar, setAreaEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Organizadores de inventario asignados',
            selector: row => row.organizadores,
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarArea(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarArea(row)}><i className="fas fa-edit text-warning"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/configuracion/areas/organizadores/${row.id}`}><i className="fas fa-table text-info"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Crear area */
    const handleAgregarArea = (area) => {
        setAreaEditar(null);
        setAreaEliminar(null);

        setAreaAgregar(area);        
    }

    /* Editar area */
    const handleEditarArea = (area) => {
        setAreaAgregar(null);
        setAreaEliminar(null);

        setAreaEditar(area);
    }

    /* Eliminar area */
    const handleEliminarArea = (area) => {
        setAreaAgregar(null);
        setAreaEditar(null);

        setAreaEliminar(area);
    }

    useEffect(() => {
        dispatch(buscarAreasConfiguracion());
    }, []);

    useEffect(() => {
        setAreas(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevaArea area={areaAgregar} reestablecerArea={handleAgregarArea} />
            <EditarArea area={areaEditar} reestablecerArea={handleEditarArea} />
            <EliminarArea area={areaEliminar} reestablecerArea={handleEliminarArea} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Áreas</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarArea({ })}>Agregar área</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={areas}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Areas;
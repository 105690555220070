import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Badge } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../actions/alert';

const DetallesEvento = ({ detalles, reestablecerDetalles, detallesCompletosEvento, editarCita, eliminarCita }) => {

    const dispatch =  useDispatch();

    const [detallesModal, setDetallesModal] = useState(false);

    const [detallesEvento, setDetallesEvento] = useState({
        id: null,
        id_colaborador: null, 
        nombre_colaborador: null, 
        id_responsable: null, 
        nombre_responsable: null, 
        tipo: null, 
        titulo: null, 
        fecha_inicio: null,
        fecha_fin: null, 
        periodos: null, 
        observaciones: null, 
        status: null
    });

    const handleCerrar = () => {
        setDetallesEvento({
            id: null, 
            id_colaborador: null, 
            nombre_colaborador: null, 
            id_responsable: null, 
            nombre_responsable: null, 
            tipo: null, 
            titulo: null, 
            fecha_inicio: null,
            fecha_fin: null, 
            periodos: null, 
            observaciones: null, 
            status: null
        });
        setDetallesModal(false);
        reestablecerDetalles(null);
    }

    /* Convertir periodos */
    const convertPeriodosTexto = (periodos, fecha) => {
        let fechas_texto = [];
        let cantidad_dia = 1000 * 60 * 60 * 24;

        if(periodos?.length > 0){
            for (var x = 0; x < periodos.length; x++) {
                let diferencia = Math.floor(new Date(periodos[x].fin).getTime() - new Date(periodos[x].inicio).getTime());

                if(Math.floor(diferencia / cantidad_dia) > 0){
                    fechas_texto.push(new Date(periodos[x].inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' al ' + new Date(periodos[x].fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }));
                }else{
                    fechas_texto.push(new Date(periodos[x].inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }));
                }
            }
        }else{
            fechas_texto.push(new Date(fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }));
        }

        return fechas_texto.length > 0 ? fechas_texto.join(', ') : 'Sin definir';
    }

    /* Detalles completos */
    const handleDetalles = async () => {
        if(detallesEvento.id_colaborador){
            detallesCompletosEvento(detallesEvento.id_colaborador);
            handleCerrar();
        }else{
            dispatch(setAlert('Selecciona primero un evento', 'danger'));
        }
    }

    useEffect(() => {
        if(detalles){
            setDetallesModal(true);

            setDetallesEvento({
                ...detallesEvento,
                id: detalles?.id,
                id_colaborador: detalles?.colaborador?.id,
                nombre_colaborador: detalles?.colaborador?.nombre,
                id_responsable: detalles?.responsable?.id,
                nombre_responsable: detalles?.responsable?.nombre,
                titulo: detalles?.title,
                fecha_inicio: detalles?.start,
                fecha_fin: detalles?.end,
                periodos: detalles?.periodos,
                tipo: detalles?.tipo,
                observaciones: detalles?.observaciones,
                status: detalles?.status
            });
        }
    }, [detalles]);

    return (
        <Fragment>
            <Modal show={detallesModal} backdrop="static" size="lg" centered onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>{detallesEvento.titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className={'col-md-' + (detallesEvento.status ? '6' : '12')}>
                            <label className="fw-bold">Fecha</label>
                            <p>{ convertPeriodosTexto(detallesEvento?.periodos, detallesEvento?.fecha_inicio) }</p>
                        </div>

                        {
                            detallesEvento.status ? 
                                <div className="col-md-6">
                                    <label className="fw-bold">Status</label>
                                    <h5><button type="button" className="btn btn-link btn-lg p-0"><Badge pill bg={detallesEvento.status == 1 ? 'warning' : (detallesEvento.status == 2 ? 'success' : 'danger')}>{detallesEvento.status == 1 ? 'Pendiente' : (detallesEvento.status == 2 ? 'Autorizada' : 'Rechazada')}</Badge></button></h5>
                                </div>
                            :
                                null
                        }

                        <div className="col-md-6">
                            <label className="fw-bold">Colaborador</label>
                            <p>{detallesEvento?.nombre_colaborador || 'Sin definir'}</p>
                        </div>

                        <div className="col-md-6">
                            <label className="fw-bold">Responsable</label>
                            <p>{detallesEvento?.nombre_responsable || 'Sin definir'}</p>
                        </div>

                        <div className="col-md-12">
                            <label className="fw-bold">Observaciones</label>
                            <p>{detallesEvento?.observaciones ? detallesEvento?.observaciones : 'Sin observaciones'}</p>
                        </div>
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => handleDetalles()}><i className="fas fa-eye fa-sm"></i> Perfil completo</button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default DetallesEvento;
import { BREADCRUMB } from "../actions/types";

const initialState = [];

const breadcrumb = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case BREADCRUMB.EDITAR:
            return payload;

        default:
            return state;
    }
}

export default breadcrumb;
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { eliminarUnidadConfiguracion } from '../../../actions/configuracion';

const EliminarUnidad = ({ unidad, reestablecerUnidad }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar unidad */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(eliminarUnidadConfiguracion(unidad?.id));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerUnidad(null);
            setEliminarModal(false);
        }
    }

    useEffect(() => {
        if(unidad){
            setEliminarModal(true);
        }
    }, [unidad]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar unidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Deseas eliminar a {unidad?.nombre}{unidad?.empresas > 0 ? ' con ' + unidad?.empresas + ' relacionadas' : ''}? Ten en cuenta que si hay productos que tengan dependencia de esta unidad se les quitará.</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarUnidad;
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../../actions/alert';
import { crearUnidadConfiguracion } from '../../../actions/configuracion';

const NuevaUnidad = ({ unidad, reestablecerUnidad }) => {

    const dispatch =  useDispatch();

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [unidadData, setUnidadData] = useState({
        nombre: null,
        descripcion: null
    });

    /* Crear unidad */
    const handleInputChange = ({ target }) => {
        setUnidadData({
            ...unidadData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async () => {
        if(unidadData.nombre){
            await setStatusProcesar(true);
            await dispatch(crearUnidadConfiguracion(unidadData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setUnidadData({
                nombre: null, 
                descripcion: null
            });
            setNuevoModal(false);
            reestablecerUnidad(null);
        }
    }

    useEffect(() => {
        if(unidad){
            setNuevoModal(true);

            setUnidadData({
                ...unidadData
            });
        }        
    }, [unidad]);

    return (
        <Modal show={nuevoModal} size="md" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar unidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={unidadData.nombre} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Descripción" id="descripcion" name="descripcion" value={unidadData.descripcion} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="descripcion">Descripción</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaUnidad;
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatTexto } from '../../../utils/generalFunctions';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { setAlert } from '../../../actions/alert';
import { crearTipoColaborador, obtenerPermisos } from '../../../actions/tipos_colaboradores';

const NuevoTipo = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Tipos de colaborador',
            path: '/colaboradores/tipos'
        },
        {
            activo: true,
            nombre: 'Nuevo tipo',
            path: '/colaboradores/tipos/nuevo'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { permisos } = useSelector(state => state.tipos_colaboradores);

    const [tipoData, setTipoData] = useState({
        nombre: null,
        permisos: null
    });

    /* Nuevo tipo */
    const handleInputChange = ({ target }) => {
        setTipoData({
            ...tipoData,
            [target.name]: target.value
        });
    }

    const handleCheckbox = ({ target }) => {
        let permisos = tipoData.permisos;

        function modificarPropiedad(obj, ruta, nuevoValor) {
            let propiedad = obj;
            
            for (let i = 0; i < ruta.length - 1; i++) {
                propiedad = propiedad[ruta[i]];
            }
            
            propiedad[ruta[ruta.length - 1]] = nuevoValor;
        }
        
        let ruta = target.id.split('-');
        let nuevoValor = target.checked;

        modificarPropiedad(permisos, ruta, nuevoValor);

        setTipoData({
            ...tipoData, 
            permisos
        });
    }

    const handleGuardarTipo = async () => {
        if(tipoData.nombre){
            await dispatch(crearTipoColaborador(tipoData));

            await navigate("/colaboradores/tipos");
        }else{
            await dispatch(setAlert('Todos los campos son obligatorios', 'danger'));
        }
    }

    /* Funciones generales */
    const convertPermisos = (permisos) => {
        const renderPermisos = (permisos, propiedad = '', nivel = 0) => {
            return Object.keys(permisos).map((clave) => {
                if (typeof permisos[clave] === 'object' && permisos[clave] !== null) {
                    let clave_compuesta = propiedad ? propiedad + '-' + clave : clave;
                    return (
                        <div className="col-md-3 my-3" key={clave_compuesta}>
                            <label className="display-block mb-2">
                                <b>{formatTexto(clave)}</b>
                            </label>
                            <div className={nivel > 0 ? 'subnivel' : ''}>
                                {renderPermisos(permisos[clave], clave_compuesta, nivel + 1)}
                            </div>
                        </div>
                    );
                } else if (typeof permisos[clave] === 'boolean') {
                    return (
                        <div className="form-check" key={propiedad + '-' + clave}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={permisos[clave]}
                                id={propiedad + '-' + clave}
                                onChange={e => handleCheckbox(e)}
                                checked={permisos[clave]}
                            />
                            <label className="form-check-label" htmlFor={propiedad + '-' + clave}>
                                {formatTexto(clave)}
                            </label>
                        </div>
                    );
                }
                return null;
            });
        };
    
        return renderPermisos(permisos);
    }

    useEffect(() => {
        dispatch(obtenerPermisos());
    }, []);

    useEffect(() => {
        if(permisos){
            setTipoData({
                ...tipoData, 
                permisos
            });
        }
    }, [permisos]);    

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 5%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #85ac11;
                        }
                        
                        .img-wrap{
                            position: relative;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 5%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #caec67;
                            width: 100%;
                            height: 200px;
                            border-radius: 5%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-5 mt-5">Nuevo tipo</h3>

                    <div className="row mb-3">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={tipoData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        {
                            tipoData.permisos ? convertPermisos(tipoData.permisos) : null
                        }
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarTipo}>Guardar</button>
                            <Link to='/colaboradores/tipos'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevoTipo;
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarServiciosConfiguracion } from '../../../actions/configuracion';

import NuevoServicio from './NuevoServicio';
import EditarServicio from './EditarServicio';
import EliminarServicio from './EliminarServicio';

const Servicios = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Servicios',
            path: '/configuracion/servicios'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.servicios);

    const [servicios, setServicios] = useState([]);

    const [servicioAgregar, setServicioAgregar] = useState(null);
    const [servicioEditar, setServicioEditar] = useState(null);
    const [servicioEliminar, setServicioEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Empresas relacionadas',
            selector: row => row.empresas,
        },
        {
            name: '',
            cell: row => {
                if(row._id){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarServicio(row)}><i className="fas fa-trash text-danger"></i></button>
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarServicio(row)}><i className="fas fa-edit text-warning"></i></button>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    /* Crear servicio */
    const handleAgregarServicio = (servicio) => {
        setServicioEditar(null);
        setServicioEliminar(null);

        setServicioAgregar(servicio);        
    }

    /* Editar servicio */
    const handleEditarServicio = (servicio) => {
        setServicioAgregar(null);
        setServicioEliminar(null);

        setServicioEditar(servicio);
    }

    /* Eliminar servicio */
    const handleEliminarServicio = (servicio) => {
        setServicioAgregar(null);
        setServicioEditar(null);

        setServicioEliminar(servicio);
    }

    useEffect(() => {
        dispatch(buscarServiciosConfiguracion());
    }, []);

    useEffect(() => {
        setServicios(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevoServicio servicio={servicioAgregar} reestablecerServicio={handleAgregarServicio} />
            <EditarServicio servicio={servicioEditar} reestablecerServicio={handleEditarServicio} />
            <EliminarServicio servicio={servicioEliminar} reestablecerServicio={handleEliminarServicio} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Servicios</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarServicio({ })}>Agregar servicio</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={servicios}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Servicios;
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarMetodosConfiguracion } from '../../../actions/configuracion';

import NuevoMetodo from './NuevoMetodo';
import EditarMetodo from './EditarMetodo';
import EliminarMetodo from './EliminarMetodo';

const Metodos = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Metodos de pago / cobro',
            path: '/configuracion/metodos'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.metodos);

    const [metodos, setMetodos] = useState([]);

    const [metodoAgregar, setMetodoAgregar] = useState(null);
    const [metodoEditar, setMetodoEditar] = useState(null);
    const [metodoEliminar, setMetodoEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Descripción',
            selector: row => row.descripcion,
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarMetodo(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarMetodo(row)}><i className="fas fa-edit text-warning"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Crear metodo */
    const handleAgregarMetodo = (metodo) => {
        setMetodoEditar(null);
        setMetodoEliminar(null);

        setMetodoAgregar(metodo);        
    }

    /* Editar metodo */
    const handleEditarMetodo = (metodo) => {
        setMetodoAgregar(null);
        setMetodoEliminar(null);

        setMetodoEditar(metodo);
    }

    /* Eliminar metodo */
    const handleEliminarMetodo = (metodo) => {
        setMetodoAgregar(null);
        setMetodoEditar(null);

        setMetodoEliminar(metodo);
    }

    useEffect(() => {
        dispatch(buscarMetodosConfiguracion());
    }, []);

    useEffect(() => {
        setMetodos(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevoMetodo metodo={metodoAgregar} reestablecerMetodo={handleAgregarMetodo} />
            <EditarMetodo metodo={metodoEditar} reestablecerMetodo={handleEditarMetodo} />
            <EliminarMetodo metodo={metodoEliminar} reestablecerMetodo={handleEliminarMetodo} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Metodos de pago / cobro</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarMetodo({ })}>Agregar metodo</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={metodos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Metodos;
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setAlert } from '../../../actions/alert';
import { editarContratacion } from '../../../actions/colaboradores';

const EditarContratacion = ({ contratacion, reestablecerContratacion }) => {

    const dispatch =  useDispatch();

    const [editarData, setEditarData] = useState({
        id: null, 
        id_colaborador: null, 
        inicio: new Date(), 
        fin: new Date(), 
        observaciones_inicio: '', 
        observaciones_fin: '', 
        status: false, 
        status_colaborador: 0
    });

    const [editarModal, setEditarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Editar contratacion */
    const handleInputChange = ({ target }) => {
        setEditarData({
            ...editarData,
            [target.name]: target.value
        });
    }

    const handleDatePicker = (name, value) => {
        setEditarData({
            ...editarData,
            [name]: value
        });
    }

    const handleProcesar = async () => {
        if(!editarData.status){
            if(editarData.inicio && editarData.fin){
                await setStatusProcesar(true);
                await dispatch(editarContratacion(editarData));
                await handleCerrar();
                await setStatusProcesar(false);
            }else{
                await dispatch(setAlert('La fecha es requerida', 'danger'));
            }
        }else{
            if(editarData.inicio){
                await setStatusProcesar(true);
                await dispatch(editarContratacion(editarData));
                await handleCerrar();
                await setStatusProcesar(false);
            }else{
                await dispatch(setAlert('La fecha es requerida', 'danger'));
            }
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setEditarData({
                id: null, 
                id_colaborador: null, 
                inicio: new Date(), 
                fin: new Date(), 
                observaciones_inicio: '', 
                observaciones_fin: '', 
                status: false, 
                status_colaborador: 0
            });
            reestablecerContratacion(null, 0);
            setEditarModal(false);
        }
    }

    useEffect(() => {
        if(contratacion){
            setEditarModal(true);

            setEditarData({
                ...editarData, 
                id: contratacion.id, 
                id_colaborador: contratacion.id_colaborador, 
                inicio: contratacion.inicio != undefined ? contratacion.inicio : null, 
                fin: contratacion.fin != undefined ? contratacion.fin : null, 
                observaciones_inicio: contratacion.observaciones_inicio != undefined ? contratacion.observaciones_inicio : null, 
                observaciones_fin: contratacion.observaciones_fin != undefined ? contratacion.observaciones_fin : null, 
                status: contratacion.status, 
                status_colaborador: contratacion.status_colaborador
            });
        }
    }, [contratacion]);

    return (
        <Modal show={editarModal} size="md" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Editar contratación de colaborador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="inicio"
                                    name="inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    autoComplete="off"
                                    selected={ editarData.inicio != null ? new Date(editarData.inicio) : '' }
                                    onChange={ date => handleDatePicker('inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="inicio">Inicio de contrato</label>
                            </div>
                        </div>
                    </div>

                    {
                        !editarData.status ?
                            <div className="col-md-12 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <DatePicker
                                            id="fin"
                                            name="fin"
                                            className="form-control text-center input-datepícker"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={editarData.inicio ? new Date(editarData.inicio) : new Date()}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            autoComplete="off"
                                            selected={ editarData.fin != null ? new Date(editarData.fin) : '' }
                                            onChange={ date => handleDatePicker('fin', date) }
                                        />
                                        <label className="label-datepicker" htmlFor="fin">Finalización de contrato</label>
                                    </div>
                                </div>
                            </div>
                        :
                            null
                    }

                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <textarea className="form-control textareCampo" placeholder="Observaciones de inicio" id="observaciones_inicio" name="observaciones_inicio" value={editarData.observaciones_inicio} onChange={e => handleInputChange(e)}></textarea>
                                <label htmlFor="observaciones_inicio">Observaciones de inicio</label>
                            </div>
                        </div>
                    </div>

                    {
                        !editarData.status ?
                            <div className="col-md-12 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <textarea className="form-control textareCampo" placeholder="Observaciones de finalización" id="observaciones_fin" name="observaciones_fin" value={editarData.observaciones_fin} onChange={e => handleInputChange(e)}></textarea>
                                        <label htmlFor="observaciones_fin">Observaciones de finalización</label>
                                    </div>
                                </div>
                            </div>
                        :
                            null
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditarContratacion;
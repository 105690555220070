import { TIPOS_COLABORADORES } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null, 
    permisos: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case TIPOS_COLABORADORES.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case TIPOS_COLABORADORES.PERMISOS:
            return {
                ...state,
                permisos: payload
            };

        case TIPOS_COLABORADORES.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case TIPOS_COLABORADORES.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( tipo => tipo.id !== payload )
            };
        
        default:
            return state;
    }
}
import axios from 'axios';

import { CALENDARIO } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarEventos = (fecha_inicial, fecha_final) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        fecha_inicial, 
        fecha_final
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/calendario/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CALENDARIO.BUSCAR,
            payload: res.data.eventos
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const informacionEvento = (evento) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(evento);

    try{
        let tipo = '';

        switch(evento.tipo){
            case 3:
                tipo = 'vacacion';
                break;

            case 4:
                tipo = 'incidencia';
                break;

            case 5:
                tipo = 'remoto';
                break;
        }

        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/colaboradores/status_` + tipo, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CALENDARIO.EDITAR,
            payload: res.data.colaborador
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarEvento = () => dispatch => {
    dispatch({
        type: CALENDARIO.DETALLES,
        payload: null
    });
}
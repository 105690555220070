import axios from 'axios';

import { INVENTARIO } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarInventario = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/inventario/listar`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: INVENTARIO.BUSCAR,
            payload: res.data.productos
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}
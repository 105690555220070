import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setAlert } from '../../../../../actions/alert';
import { limpiarUbicacionConfiguracion, obtenerUbicacionConfiguracion, editarUbicacionConfiguracion } from '../../../../../actions/configuracion';

const EditarUbicacion = ({ ubicacion, reestablecerUbicacion }) => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.configuracion.ubicaciones);

    const [editarModal, setEditarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [ubicacionData, setUbicacionData] = useState({
        id: null,
        id_organizador: null, 
        nombre: null
    });

    /* Editar ubicacion */
    const handleInputChange = ({ target }) => {
        setUbicacionData({
            ...ubicacionData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async () => {
        if(ubicacionData.nombre && ubicacionData.id_organizador != 0 && ubicacionData.id_organizador != '0'){
            await setStatusProcesar(true);
            await dispatch(editarUbicacionConfiguracion(ubicacionData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setUbicacionData({
                id: null,
                id_organizador: null, 
                nombre: null
            });

            setEditarModal(false);
            reestablecerUbicacion(null);
        }
    }

    useEffect(() => {
        dispatch(limpiarUbicacionConfiguracion());
    }, []);

    useEffect(() => {
        if(ubicacion){
            setEditarModal(true);
            
            dispatch(obtenerUbicacionConfiguracion(ubicacion?.id));

            setUbicacionData({
                ...ubicacionData,
                id: ubicacion?.id
            });
        }
    }, [ubicacion]);

    useEffect(() => {
        if(detalles){
            setUbicacionData({
                ...ubicacionData,
                id: detalles?.id, 
                id_organizador: detalles?.id_organizador, 
                nombre: detalles?.nombre
            });
        }
    }, [detalles]);

    return (
        <Modal show={editarModal} backdrop="static" size="md" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Editar ubicación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={ubicacionData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Guardar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Guardar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditarUbicacion;
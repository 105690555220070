export const formatNumber = (numero, decimales, prefijo, sufijo) => {
    let number = (Math.round(numero * 100) / 100).toFixed(decimales);
    const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: decimales });
    const finalFormated = prefijo + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + sufijo;
    return finalFormated;    
}

export const formatTexto = (texto) => {
    let textoConEspacios = texto.replace(/_/g, ' ');
    let textoCapitalizado = textoConEspacios.charAt(0).toUpperCase() + textoConEspacios.slice(1);
    
    return textoCapitalizado;
}
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { editarBreadcrumb } from '../../../../actions/breadcrumb';
import { obtenerAreaConfiguracion, buscarOrganizadoresConfiguracion } from '../../../../actions/configuracion';

import NuevoOrganizador from './NuevoOrganizador';
import EditarOrganizador from './EditarOrganizador';
import EliminarOrganizador from './EliminarOrganizador';

const Organizadores = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Áreas',
            path: '/configuracion/areas'
        },
        {
            activo: true,
            nombre: 'Organizadores',
            path: '/configuracion/areas/organizadores/'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.organizadores);
    const { detalles } = useSelector(state => state.configuracion.areas);

    const { id } = useParams();

    const [organizadores, setOrganizadores] = useState([]);

    const [organizadorAgregar, setOrganizadorAgregar] = useState(null);
    const [organizadorEditar, setOrganizadorEditar] = useState(null);
    const [organizadorEliminar, setOrganizadorEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Ubicaciones existentes',
            selector: row => row.ubicaciones,
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarOrganizador(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarOrganizador(row)}><i className="fas fa-edit text-warning"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/configuracion/areas/organizadores/${row.id_area}/ubicaciones/${row.id}`}><i className="fas fa-location-dot text-info"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Crear organizador */
    const handleAgregarOrganizador = (organizador) => {
        setOrganizadorEditar(null);
        setOrganizadorEliminar(null);

        setOrganizadorAgregar(organizador);        
    }

    /* Editar organizador */
    const handleEditarOrganizador = (organizador) => {
        setOrganizadorAgregar(null);
        setOrganizadorEliminar(null);

        setOrganizadorEditar(organizador);
    }

    /* Eliminar organizador */
    const handleEliminarOrganizador = (organizador) => {
        setOrganizadorAgregar(null);
        setOrganizadorEditar(null);

        setOrganizadorEliminar(organizador);
    }

    useEffect(() => {
        setOrganizadores(listado);
    }, [listado]);

    useEffect(() => {
        if(id){
            dispatch(obtenerAreaConfiguracion(id));
            dispatch(buscarOrganizadoresConfiguracion(id));

            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    return (
        <Fragment>

            <NuevoOrganizador organizador={organizadorAgregar} reestablecerOrganizador={handleAgregarOrganizador} />
            <EditarOrganizador organizador={organizadorEditar} reestablecerOrganizador={handleEditarOrganizador} />
            <EliminarOrganizador organizador={organizadorEliminar} reestablecerOrganizador={handleEliminarOrganizador} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Organizadores {detalles?.nombre ? ' de ' + detalles.nombre : ''}</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarOrganizador({ id_area: id })}>Agregar organizador</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={organizadores}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Organizadores;
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { editarBreadcrumb } from '../../../../../actions/breadcrumb';
import { obtenerSubcategoriaConfiguracion, buscarSubsubcategoriasConfiguracion } from '../../../../../actions/configuracion';

import NuevaSubsubcategoria from './NuevaSubsubcategoria';
import EditarSubsubcategoria from './EditarSubsubcategoria';
import EliminarSubsubcategoria from './EliminarSubsubcategoria';

const Subsubcategorias = () => {

    const [rutasBread, setRutasBread] = useState([
        {
          activo: false,
          nombre: 'Áreas',
          path: '/configuracion/categorias'
        },
        {
          activo: false,
          nombre: 'Subcategorias',
          path: '/configuracion/categorias/subcategorias/'
        }, 
        {
          activo: true,
          nombre: 'Subsubcategorias',
          path: '/configuracion/categorias/subcategorias/'
        }
    ]);

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.subsubcategorias);
    const { detalles } = useSelector(state => state.configuracion.subcategorias);

    const { id, id_subcategoria } = useParams();

    const [subsubcategorias, setSubsubcategorias] = useState([]);

    const [subsubcategoriaAgregar, setSubsubcategoriaAgregar] = useState(null);
    const [subsubcategoriaEditar, setSubsubcategoriaEditar] = useState(null);
    const [subsubcategoriaEliminar, setSubsubcategoriaEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Descripción',
            selector: row => row.descripcion,
            grow: 2
        },
        {
            name: 'Productos existentes',
            selector: row => row.productos,
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarSubsubcategoria(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarSubsubcategoria(row)}><i className="fas fa-edit text-warning"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Crear subsubcategoria */
    const handleAgregarSubsubcategoria = (subsubcategoria) => {
        setSubsubcategoriaEditar(null);
        setSubsubcategoriaEliminar(null);

        setSubsubcategoriaAgregar(subsubcategoria);        
    }

    /* Editar subsubcategoria */
    const handleEditarSubsubcategoria = (subsubcategoria) => {
        setSubsubcategoriaAgregar(null);
        setSubsubcategoriaEliminar(null);

        setSubsubcategoriaEditar(subsubcategoria);
    }

    /* Eliminar subsubcategoria */
    const handleEliminarSubsubcategoria = (subsubcategoria) => {
        setSubsubcategoriaAgregar(null);
        setSubsubcategoriaEditar(null);

        setSubsubcategoriaEliminar(subsubcategoria);
    }

    useEffect(() => {
        setSubsubcategorias(listado);
    }, [listado]);

    useEffect(() => {
        if(id && id_subcategoria){
            let rutasActualizadas = [...rutasBread];
    
            if (!rutasActualizadas[1].path.includes(id)) {
                rutasActualizadas[1].path = rutasActualizadas[1].path + id;
            }
            if (!rutasActualizadas[2].path.includes(id) && !rutasActualizadas[2].path.includes(id_subcategoria)) {
                rutasActualizadas[2].path = rutasActualizadas[2].path + id + '/subsubcategorias/' + id_subcategoria;
            }
        
            setRutasBread(rutasActualizadas);
        }
    
        if(id_subcategoria){
            dispatch(obtenerSubcategoriaConfiguracion(id_subcategoria));
            dispatch(buscarSubsubcategoriasConfiguracion(id_subcategoria));
        
            dispatch(editarBreadcrumb(rutasBread));
        }
      }, [id, id_subcategoria]);

    return (
        <Fragment>

            <NuevaSubsubcategoria subsubcategoria={subsubcategoriaAgregar} reestablecerSubsubcategoria={handleAgregarSubsubcategoria} />
            <EditarSubsubcategoria subsubcategoria={subsubcategoriaEditar} reestablecerSubsubcategoria={handleEditarSubsubcategoria} />
            <EliminarSubsubcategoria subsubcategoria={subsubcategoriaEliminar} reestablecerSubsubcategoria={handleEliminarSubsubcategoria} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Subsubcategorias {detalles?.nombre ? ' de ' + detalles.nombre : ''}</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarSubsubcategoria({ id_subcategoria })}>Agregar subsubcategoria</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={subsubcategorias}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Subsubcategorias;
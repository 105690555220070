import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../../../../actions/alert';
import { crearSubsubcategoriaConfiguracion } from '../../../../../actions/configuracion';

const NuevaSubsubcategoria = ({ subsubcategoria, reestablecerSubsubcategoria }) => {

    const dispatch =  useDispatch();

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [subsubcategoriaData, setSubsubcategoriaData] = useState({
        id: null,
        subcategoria: false,
        nombre: '', 
        descripcion: ''
    });

    /* Crear subsubcategoria */
    const handleInputChange = ({ target }) => {
        setSubsubcategoriaData({
            ...subsubcategoriaData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async () => {
        if(subsubcategoriaData.nombre && subsubcategoriaData.id != 0 && subsubcategoriaData.id != '0'){
            await setStatusProcesar(true);
            await dispatch(crearSubsubcategoriaConfiguracion(subsubcategoriaData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setSubsubcategoriaData({
                id: null,
                nombre: '', 
                descripcion: ''
            });

            setNuevoModal(false);
            reestablecerSubsubcategoria(null);
        }
    }

    useEffect(() => {
        if(subsubcategoria){
            setNuevoModal(true);

            setSubsubcategoriaData({
                ...subsubcategoriaData, 
                id: subsubcategoria.id_subcategoria, 
                subcategoria: subsubcategoria.id_subcategoria == 0 ? false : true
            });
        }        
    }, [subsubcategoria]);

    return (
        <Modal show={nuevoModal} size="md" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar subsubcategoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={subsubcategoriaData.nombre} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <textarea className="form-control" placeholder="Descripción" id="descripcion" name="descripcion" value={subsubcategoriaData.descripcion} onChange={e => handleInputChange(e)} rows="3"></textarea>
                                <label htmlFor="descripcion">Descripción</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaSubsubcategoria;
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarProductosConfiguracion, buscarUnidadesConfiguracion } from '../../../actions/configuracion';

import { formatNumber } from '../../../utils/generalFunctions';

import NuevoProducto from './NuevoProducto';
import EditarProducto from './EditarProducto';
import DetallesProducto from './DetallesProducto';
import EliminarProducto from './EliminarProducto';
import EliminarProductos from './EliminarProductos';

const Productos = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Productos',
            path: '/configuracion/productos'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.productos);

    const [productos, setProductos] = useState([]);

    const [productosEliminar, setProductosEliminar] = useState([]);

    const [productoAgregar, setProductoAgregar] = useState(null);
    const [productoEditar, setProductoEditar] = useState(null);
    const [productoDetalles, setProductoDetalles] = useState(null);
    const [productoEliminar, setProductoEliminar] = useState(null);
    const [multipleEliminar, setMultipleEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: '',
            cell: row => {
                return(
                    <div className="form-check" key={'producto-' + row.id}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={'producto-' + row.id}
                            onChange={e => handleCheckbox(e)}
                            checked={productosEliminar.includes(row.id + '') ? true : false}
                        />
                    </div>
                )
            }
        }, 
        {
            name: 'Nombre',
            selector: row => row.nombre,
            grow: 2
        },
        {
            name: 'Categoría',
            selector: row => row.categoria.nombre || 'Sin definir'
        },
        {
            name: 'Subcategoría',
            selector: row => row.subcategoria.nombre || 'Sin definir'
        },
        {
            name: 'Subsubcategoría',
            selector: row => row.subsubcategoria.nombre || 'Sin definir'
        },
        {
            name: 'Código de barras',
            selector: row => row.codigo_barras
        },
        {
            name: 'Costo',
            selector: row => formatNumber(row.precio_compra, 2, '$ ', '')
        },
        {
            name: 'Precio',
            selector: row => formatNumber(row.precio_venta, 2, '$ ', '')
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarProducto(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarProducto(row)}><i className="fas fa-edit text-warning"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Crear producto */
    const handleAgregarProducto = (producto) => {
        setProductoEditar(null);
        setProductoDetalles(null);
        setProductoEliminar(null);
        setMultipleEliminar(null);

        setProductoAgregar(producto);        
    }

    /* Editar producto */
    const handleEditarProducto = (producto) => {
        setProductoAgregar(null);
        setProductoDetalles(null);
        setProductoEliminar(null);
        setMultipleEliminar(null);

        setProductoEditar(producto);
    }

    /* Detalles producto */
    const handleDetallesProducto = (producto) => {
        setProductoAgregar(null);
        setProductoEditar(null);
        setProductoEliminar(null);
        setMultipleEliminar(null);

        setProductoDetalles(producto);
    }

    /* Eliminar producto */
    const handleEliminarProducto = (producto) => {
        setProductoAgregar(null);
        setProductoDetalles(null);
        setProductoEditar(null);
        setMultipleEliminar(null);

        setProductoEliminar(producto);
    }

    /* Eliminar productos */
    const handleCheckbox = ({ target }) => {
        const productoId = target.id.split('-')[1];
        console.log(`Checkbox con id ${productoId} ha sido ${target.checked ? 'marcado' : 'desmarcado'}`);
    
        setProductosEliminar(prevState => {
            const newState = target.checked ? [...prevState, productoId] : prevState.filter(id => id !== productoId);
            console.log("Nuevo estado de productosEliminar:", newState);
            return newState;
        });
    }    

    const handleEliminarProductos = (productos) => {
        setProductoAgregar(null);
        setProductoDetalles(null);
        setProductoEditar(null);
        setProductoEliminar(null);

        setMultipleEliminar(productos);

        if(productos?.length == 0){
            setProductosEliminar([]);
        }
    }

    useEffect(() => {
        dispatch(buscarUnidadesConfiguracion());
        dispatch(buscarProductosConfiguracion());
    }, []);

    useEffect(() => {
        setProductos(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <NuevoProducto producto={productoAgregar} reestablecerProducto={handleAgregarProducto} />
            <EditarProducto producto={productoEditar} reestablecerProducto={handleEditarProducto} />
            <DetallesProducto producto={productoDetalles} reestablecerProducto={handleDetallesProducto} />
            <EliminarProducto producto={productoEliminar} reestablecerProducto={handleEliminarProducto} />
            <EliminarProductos productos={multipleEliminar} reestablecerProductos={handleEliminarProductos} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Productos</h3>

                    <div className="d-flex justify-content-end">
                        {
                            productosEliminar.length > 0 &&
                                <button type="button" className="btn btn-danger mx-1" onClick={() => handleEliminarProductos(productosEliminar)}>Eliminar {productosEliminar.length} producto{productosEliminar.length == 1 ? '' : 's'}</button>
                        }
                        <button type="button" className="btn btn-primary mx-1" onClick={() => handleAgregarProducto({ })}>Agregar producto</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={productos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Productos;